.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1000px;
}
.tab {
  cursor: pointer;
  font-weight: 500;
  font-size: 25px;
  margin: 20px 0;
}
.tab-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  background-color: #100f0f;
  padding: 50px;
}
.tab-content {
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  padding: 25px;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width: 300px) {
  .tabs {
    flex-direction: column;
  }
  .tab-list {
    flex-direction: row;
    width: 100%;
    height: fit-content;
  }
  .tab {
    margin: 0 5px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .tabs {
    flex-direction: column;
  }
  .tab-list {
    flex-direction: row;
    width: 100%;
    height: fit-content;
  }
  .tab {
    margin: 0 10px;
    font-size: 18px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .tabs {
    flex-direction: column;
  }
  .tab-list {
    flex-direction: row;
    width: 100%;
    height: fit-content;
  }
  .tab {
    margin: 0 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .message {
    width: 500px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .message {
    width: 600px;
  }
}
