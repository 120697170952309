.specialDish {
  margin: 20px 0;
}
.specialDishContainer {
  margin: auto;
  width: 75%;
  padding: 25px;
}
.imgContainer {
  text-align: center;
}
.imgContainer img {
  width: 75%;
  border-radius: 6px;
}
.detailsContainer {
  margin: auto;
  width: 75%;
}

.detailsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.detailsText {
  font-size: 45px;
  margin: 0;
  font-family: "Forum", cursives;
}
.detailsP {
  font-size: 21px;
}

.detailsText2 {
  font-size: 35px;
  margin: 0;
  font-family: "Forum", cursives;
}
/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (max-width: 300px) {
  .specialDishContainer {
    width: 100%;
  }
  .imgContainer img {
    width: 100%;
  }
  .detailsContainer {
    width: 100%;
  }
  .detailsItem {
    flex-direction: column;
    align-items: flex-start;
  }
  .detailsText {
    font-size: 25px;
  }
  .detailsText2 {
    font-size: 20px;
  }
  .detailsP {
    font-size: 18px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .specialDishContainer {
    width: 100%;
  }
  .imgContainer img {
    width: 100%;
  }
  .detailsItem {
    flex-direction: column;
    align-items: flex-start;
  }
  .detailsContainer {
    width: 100%;
  }
  .detailsText2 {
    font-size: 30px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .specialDishContainer {
    width: 90%;
  }
  .imgContainer img {
    width: 100%;
  }
  .detailsItem {
    flex-direction: column;
    align-items: flex-start;
  }
  .detailsContainer {
    width: 100%;
  }
  .detailsText {
    font-size: 50px;
  }
  .detailsText2 {
    font-size: 40px;
  }
  .detailsP {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .specialDishContainer {
    width: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .specialDishContainer {
    width: 100%;
  }
}
