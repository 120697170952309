.form {
  max-width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input,
textarea,
select {
  padding: 10px;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #4caf50;
}

/* button {
  background-color: var(--main-color);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0;
} */

button:hover {
  background-color: #000;
}

.menuFormContainer {
  flex: 0.5;
}
.menuItems {
  /* min-height: 600px;
  flex: 0.3;
  height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: #000;
}
.menuBtn {
  background-color: var(--main-color);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0;
}
.menuInput {
  margin: 10px 0;
}
.menuTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
.menuTitle i {
  font-size: 25px;
  cursor: pointer;
}
