.viewContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 50px 0;
}
.dishContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 0.3;
}
.codeContainer {
  display: flex;
  text-align: center;
  flex-direction: column;
  flex: 0.3;
}
/* .dish {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.code {
  width: 100%;
  object-fit: cover;
  margin: auto;
} */
.scanTxt {
  font-size: 30px;
  margin: 40px;
  line-height: 0;
}

@media screen and (max-width: 398px) {
  .viewContainer {
    flex-direction: column;
  }
  .codeContainer,
  .dishContainer {
    width: 75%;
    margin: auto;
  }
  .scanTxt {
    font-size: 18px;
    margin: 40px 0;
  }
}

@media screen and (min-width: 398px) and (max-width: 992px) {
  .viewContainer {
    flex-direction: column;
  }
  .codeContainer,
  .dishContainer {
    width: 75%;
    margin: 25px auto;
  }
}
