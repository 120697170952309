.reservationCard {
  background-color: rgb(228, 197, 144);
  padding: 10px;
  margin: 20px 0;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.reservationCardContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.cardItem {
  margin: 20px;
  text-align: center;
  width: 150px;
}
.message {
  margin: 0 20px;
  width: 650px;
  word-wrap: break-word;
}

.msgTxt {
  margin: 0;
  line-height: 1.2;
}

.trash-icon {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
  font-size: 25px;
  cursor: pointer;
  margin: 15px;
}
.tableBtn {
  padding: 15px;
  background-color: rgb(13, 24, 25);
  color: #fff;
  border-radius: 6px;
  font-weight: 500;
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (max-width: 300px) {
  .message {
    width: 150px;
    word-wrap: break-word;
    margin: auto;
    text-align: center;
  }
  .reservationCard {
    display: block;
    text-align: center;
    padding: 5px;
    width: 200px;
  }
  .tableBtn {
    padding: 10px;
    font-size: 18px;
  }
  .tableBtn {
    padding: 5px;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .reservationCardContainer {
    justify-content: center;
  }
  .trash-icon-container {
    text-align: right;
  }
}
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .message {
    width: 250px;
    word-wrap: break-word;
    margin: auto;
    text-align: left;
  }
  .reservationCard {
    display: block;
    width: 300px;
    text-align: center;
    padding: 25px;
  }
  .reservationCardContainer {
    justify-content: flex-start;
  }
  .cardItem {
    margin: 5px;
    text-align: left;
  }
  .trash-icon-container {
    text-align: right;
  }
  .tableBtn {
    padding: 10px;
    font-size: 18px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .message {
    width: 470px;
    margin: 0 20px;
    word-wrap: break-word;
  }

  .reservationCard {
    /* display: block; */
    width: 750px;
  }
  .reservationCardContainer {
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .message {
    width: 450px;
    margin: 0 40px;
    word-wrap: break-word;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .message {
    width: 600px;
    margin: 0 40px;
  }
}
