.menuItem {
  background-color: rgb(228, 197, 144);
  padding: 15px;
  margin: 20px;
  border-radius: 6px;
  /* flex: 0.3; */
  width: 400px;
  height: 650px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menuItemContainer {
  flex: 1;
}
.menuItem img {
  padding: 15px;
}
.imageContainer {
  text-align: center;
}
.imageContainer img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 25px;
}
.itemDetails {
  margin: 10px 0;
}
.itemDetails p {
  height: 100px;
}
.itemDetails h3 {
  color: black;
  font-weight: 800;
}
.iconsContainer {
  display: flex;
  flex-direction: column;
}
.iconsContainer i {
  font-size: 25px;
  margin: 10px 0;
  cursor: pointer;
}
/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (max-width: 300px) {
  .menuItem {
    width: 200px;
    display: block;
    height: fit-content;
  }
  .iconsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .itemDetails h3 {
    margin: 15px 0;
  }
  .itemDetails p {
    margin: 15px 0;
    height: fit-content;
  }
}
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .menuItem {
    width: 300px;
    display: block;
    height: fit-content;
  }
  .iconsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .itemDetails h3 {
    margin: 15px 0;
  }
  .itemDetails p {
    margin: 15px 0;
    height: fit-content;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) and (max-width: 992px) {
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
}
