.menuItemForm {
  width: 100%;
  margin: auto;
  padding: 10px;
}

.menuItemForm h2 {
  max-width: 50%;
  margin: 15px auto;
}
/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (max-width: 300px) {
  .menuItemForm {
    width: 100%;
  }
  .menuItemForm h2 {
    max-width: 100%;
    margin: 15px auto;
  }
  .form {
    max-width: 100%;
  }
}
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .menuItemForm {
    width: 100%;
  }
  .menuItemForm h2 {
    max-width: 80%;
  }
  .form {
    max-width: 80%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) and (max-width: 992px) {
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
}
